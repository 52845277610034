import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout/Layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO mdxType="SEO" />
    <h1>{`Samuel Gordalina`}</h1>
    <br />
    <p>{`Hi, my name is Samuel Gordalina. I live in San Francisco, California and am originally from sunny Portugal. Over the years I have developed a passion for technology and dedicated countless hours to the art of code.`}</p>
    <p>{`By trade I am a software engineer proficient in designing, architecting and building web, mobile, and infrastructure applications. I consult for companies ranging from small startups to large enterprises `}{`—`}{` sometimes as founder.`}</p>
    <p>{`My hobbies include contributing to `}<a href="https://github.com/gordalina">{`open source software`}</a>{`, dabbling in travel `}<Link to="/photography" mdxType="Link">{`photography`}</Link>{`, `}<Link to="/music" mdxType="Link">{`music production`}</Link>{`, motorcycle riding, and occasionally `}<Link to="/articles" mdxType="Link">{`writing`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      